import { createWebHistory } from 'vue-router'
import translations from '@/lang/translations'
import { createLangRouter } from 'vue-lang-router'

const routes = [
  {
    path: '/configuration/:configurationGuid',
    name: 'Home',
    component: function () {
      return import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    }
  },
  {
    path: '/configuration/:configurationGuid/thanks',
    name: 'ThanksView',
    component: function () {
      return import(/* webpackChunkName: "thanks" */ '../views/ThanksView.vue')
    }
  },
  {
    path: '/configuration/:configurationGuid/questions/:questionGuid',
    name: 'QuestionsView',
    component: function () {
      return import(/* webpackChunkName: "question_view" */ '../views/QuestionsView.vue')
    }
  },
  {
    path: '/configuration/:configurationGuid/survey_finished',
    name: 'SurveyFinishedView',
    component: function () {
      return import(/* webpackChunkName: "survey_finished" */ '../views/SurveyFinishedView.vue')
    }
  }
]

const langRouterOptions = {
  defaultLanguage: 'en',
  translations
}
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
}
const router = createLangRouter(langRouterOptions, routerOptions)

router.beforeEach((to, from, next) => {
  // set app direction
  const lang = to.path.split('/')[1]
  if (lang) {
    document.dir = lang === 'ar' ? 'rtl' : 'ltr'
    document.querySelector('html').lang = lang
  }

  next()
})

export default router
