import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from 'vue-lang-router'
import 'bootstrap'
import './styles/global.scss'
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(store).use(router).use(i18n).mount('#app')
