import state from './state'
import { i18n } from 'vue-lang-router'
import axios from 'axios'


export default {
  namespaced: true,
  state: state,
  actions: {
    getQuestions (store, configurationGuid) {
      axios.get(
        '/api/Survey/' + configurationGuid + '?languageCode=' + i18n.global.locale
      ).then((res) => {
        store.commit('setQuestions', res.data.surveyQuestions);
      })
        .catch(() => {
          store.commit('setQuestions', []);
        })
    },
    sendQuestions (store, questionConfigurationGuid) {
      const data = {
        questions: [],
        questionConfigurationGuid: questionConfigurationGuid,
        languageCode: i18n.global.locale
      }

      if (localStorage.getItem('choices')) {
        store.state.questions.forEach(q => {
          let question = {}
          question.questionGuid = q.questionGuid
          question.answers = []

          if (q.choice) {
            if (q.type === 'radiogroup') {
              question.answers.push({
                answerGuid: q.choice.choiceGuid,
                answerValue: q.choice.choicePosition
              })
            } else if (q.type === 'checkbox') {
              let choicesGUIDs = q.choice.choicesGUIDs.split(',')
              let choicesPositions = q.choice.choicesPositions.split(',')
              for (let i=0; i<choicesGUIDs.length; i++) {
                question.answers.push({
                  answerGuid: choicesGUIDs[i],
                  answerValue: choicesPositions[i]
                })
              }
            } else if (q.type === 'text' || q.type === 'comment') {
              question.answers.push({
                answerGuid: "",
                answerValue: q.choice.choiceString
              })
            }
          } else {
            question.answers = []
          }

          data.questions.push(question);
        })
      }

      axios.post(
        '/api/SurveyResult',
        data
      ).then(/* res */() => {
        // delete stored choices
        localStorage.removeItem('choices')
      })
        .catch(() => {})
    }
  },
  getters: {
    getQuestions (state) {
      return state.questions
    },
    getCurrentQuestionIndex (state) {
      return id => {
        for (let i = 0; i < state.questions.length; i++) {
          if (state.questions[i].questionGuid === id) {
            return i
          }
        }
      }
    },
    getFirstQuestionId (state) {
      return state.questions[0].questionGuid
    },
    getQuestionWithId (state) {
      return id => {
        return state.questions.find(q => q.questionGuid === id)
      }
    },
    isTheFirstQuestion (state) {
      return id => {
        return state.questions[0].questionGuid === id
      }
    },
    isTheLastQuestion (state) {
      return id => {
        return state.questions[state.questions.length - 1].questionGuid === id
      }
    },
    getPreviousQuestionId: function (state) {
      return id => {
        for (let i = 0; i < state.questions.length; i++) {
          if (state.questions[i].questionGuid === id) {
            return state.questions[i - 1].questionGuid
          }
        }
      }
    },
    getNextQuestionId: function (state) {
      return id => {
        for (let i = 0; i < state.questions.length; i++) {
          if (state.questions[i].questionGuid === id) {
            return state.questions[i + 1].questionGuid
          }
        }
      }
    },
    wasQuestionAnswered (state) {
      return id => {
        const question = state.questions.find(q => q.questionGuid === id)
        if (question && question.choice) {
          if (question.type === 'radiogroup') {
            return question.choice.choiceGuid ? question.choice.choiceGuid.length > 0 : false
          } else if (question.type === 'checkbox') {
            return question.choice.choicesGUIDs ? question.choice.choicesGUIDs.length > 0 : false
          } else if (question.type === 'text' || question.type === 'comment') {
            return question.choice.choiceString ? question.choice.choiceString.length > 0 : false
          }
        } else {
          return false
        }
      }
    }
  },
  mutations: {
    setQuestions (state, questions) {
      let question
      const newQuestionsArray = []

      for (let i = 0; i < questions.length; i++) {
          question = questions[i]

          // get selected choice from localstorage
          if (localStorage.getItem('choices') && localStorage.getItem('VueAppLanguage') === i18n.global.locale) {
            const choicesFromLocalStorage = JSON.parse(localStorage.getItem('choices'))
            question.choice = choicesFromLocalStorage[question.questionGuid]
          }
          newQuestionsArray.push(question)
      }

      state.questions = newQuestionsArray
    },
    addChoice (state, choiceObj) {
      // get choices from Storage and save it
      const choicesFromLocalStorage = JSON.parse(localStorage.getItem('choices')) || {}
      choicesFromLocalStorage[choiceObj.questionGuid] = choiceObj
      localStorage.setItem('choices', JSON.stringify(choicesFromLocalStorage))

      const question = state.questions.find(q => q.questionGuid === choiceObj.questionGuid)
      question.choice = choiceObj
    }
  }
}
