export default {
  en: {
    name: 'English',
    load: () => { return import('./en.json') }
  },
  de: {
    name: 'Deutsch',
    load: () => { return import('./de.json') }
  },
  ar: {
    name: 'العربية',
    load: () => { return import('./ar.json') }
  },
  fi: {
    name: 'Finnisch',
    load: () => { return import('./fi.json') }
  },
  pl: {
    name: 'Polnisch',
    load: () => { return import('./pl.json') }
  },
  it: {
    name: 'Italienisch',
    load: () => { return import('./it.json') }
  },
  ru: {
    name: 'Russisch',
    load: () => { return import('./ru.json') }
  }
}
